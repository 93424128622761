<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.1665 6.35147C16.6979 5.88284 15.9381 5.88284 15.4694 6.35147C15.0008 6.8201 15.0008 7.5799 15.4694 8.04853L22.2209 14.8H6.2C5.53726 14.8 5 15.3373 5 16C5 16.6628 5.53726 17.2 6.2 17.2H22.2209L15.4694 23.9515C15.0008 24.4201 15.0008 25.1799 15.4694 25.6485C15.9381 26.1172 16.6979 26.1172 17.1665 25.6485L25.9665 16.8485C26.4351 16.3799 26.4351 15.6201 25.9665 15.1515L17.1665 6.35147Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconCtaRight",
});
</script>
